@font-face {
  font-family: Lato;
  src: url(./lato/Lato-Regular.ttf),url(./lato/Lato-Black.ttf),url(./lato/Lato-Bold.ttf), url(./lato/Lato-Medium.ttf ) ;
}

@font-face {
  font-family: Lora;
  src: url(./lora/Lora-Regular.ttf),url(./lora/Lora-Bold.ttf),url(./lora/Lora-BoldItalic.ttf), url(./lora/Lora-Italic.ttf) ;
}
html {
  scroll-behavior: smooth;
}
body h1,h2,h3,h4,h5,h6,p,a {font-family: 'lato';}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {background: #fff !important;}

/*tricore 23 css start*/



.text-center.col a.nav-link {text-decoration: underline;color: blue;}
.text-center.col .fade.alert.alert-danger.show {max-width: 420px;margin: 10px auto;}
.container .mt-3.row .col-md-4.offset-md-4 {width: 45%;margin: 0 auto;}
.container .mt-3.row .col-md-4.offset-md-4 .form-floating {border: 1px solid #00cefb;border-radius: 5px;}
.col-md-4.offset-md-4 .form-floating textarea#floatingTextarea2 {padding: 30px;line-height: 1.4;font-family: auto;}

/*header section css strat*/

.normal-header .header-section {background-color: #0e3848;min-height: 380px;}

.small-header .header-section {background-color: #0e3848;}
.footer-section {background-color: #0e3848; min-height: 150px;margin-top: 40px;}

.logo {padding-top: 80px;}

.container{max-width: 1340px;margin: 0 auto;}

.header-section .logo a img { width: 220px;margin: 0 auto;}


/*header section css end*/

/*Register section css start*/

.register{position: relative;}
.register .register-form{ position: absolute;width: 100%;top: -250px;border: 1px solid #92d9f8;padding: 50px;}
.card-header {border: none;background: transparent;}

.register .register-form .card-header {
  background: transparent;
  border: none;
}

.register .register-form .card-header b {
  font-family: 'Lato';
  font-size: 45px;
  font-weight: bolder;
}

.card-body .col input.form-control {
  padding: 25px;
  border: 1px solid #92d9f8;
}

.form-check.form-check-inline input {
  border: 1px solid #92d9f8;
  border-radius: 4px;
  padding: 10px;
}

.form-check.form-check-inline label.form-check-label {
  padding-top: 2px;
  padding-left: 6px;
}

.text-center.col input.btn.btn-primary {
  background-color: #b1d836;
  border: 1px solid #b1d836;
  color: #000;
  padding: 20px 50px;
  border: none;
  border-radius: 40px;
  box-shadow: none;
}
.text-center.col input.btn.btn-primary:hover {
  background-color: #31abf7;
  border: 1px solid #31abf7;
}

.text-center.col {
  padding-top: 20px;
}

.register .register-form span {
  color: #606060;
  font-size: 18px;
  max-width: 380px;
  margin: 0 auto;
}

.register .register-form span a {
  color: blue;
  font-size: 18px;
  text-decoration: underline;
  display: inline-block;
}

.radio-tag {padding-left: 35px;}
.radio-tag p {font-size: 22px;color: #606060;padding-bottom: 10px;}
.radio-tag span {font-size: 22px;color: #606060;font-family: 'Lato';}

/*Register section css end*/

/* login page css strat */
input[type=date]::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}
.page-box {margin-top: -120px;background-color: #fff;border-radius: 5px;border: 1px solid #31abf7;}
.css-18yadix {padding: 0;margin: 50px auto;min-height: 100%;justify-content: start;}
.page-box .title-content h2 {font-size: 45px;text-align: center;font-family: 'Lato-Medium';color: #081c23;font-weight: 600;}
.page-box .title-content p {text-align: center;color: #606060;font-size: 22px;font-family: 'lora';}
.page-box .title-content a {text-align: center;color: #0fa0fa;font-size: 20px;font-family: 'lora';}
.title-content {text-align: center;padding-bottom: 30px;}
/* .form-section input {padding: 23px;border-radius: 4px;border: 1px solid #31abf7;} */
.form-section .MuiOutlinedInput-notchedOutline {border-color: #31abf7 !important;border-radius: 5px;}
/* .form-section .css-1lwbda4-MuiStack-root>:not(style)+:not(style){margin-top: 10px;} */
  .form-section > div >:not(style)+:not(style){margin-top: 10px;}
  video#_video {aspect-ratio: 16 / 9;}

.form-section .page-button {font-size: 20px;border: 1px solid #b1d836;box-shadow: none;margin-top: 30px !important;background-color: #b1d836;width: 40%;margin: 0 auto;padding: 35px 35px;color: #081c23;border-radius: 1000px;font-family: lato;}
.form-section .page-button:hover {background-color: #31abf7;border: 1px solid #31abf7;}

.form-section a.nav-link {text-align: center;color: #0fa0fa;font-size: 20px;font-family: 'lora';margin-top: 35px !important;}
.page-alert{margin-bottom: 30px;color: #a94442;background-color: #f2dede;border-color: #ebccd1;}

.patient_login_text {padding-top: 20px;}
/* login page css end */

.css-v82cpl {display: block;}
.user-name {text-align: center;color: #fff;padding-top: 70px;font-size: 20px;padding-bottom: 25px;}
.css-r6zoww {margin-right: 36px;}
.logout-icon {
  float: right;
  margin: 20px;
position: absolute;
right: 0;}

/* Account management page css start */

.page-box-2 {margin: 40px auto;background-color: #fff;border-radius: 5px;border: 1px solid #31abf7;/*max-width: 900px*/;padding: 30px !important;}
/* .page-box-2 ul.MuiList-root.MuiList-padding.css-1mcnwpj-MuiList-root {max-width: 70%;margin: 0 auto;} */
.page-box-2 .css-1lwbda4-MuiStack-root {justify-content: center !important;text-align: center;margin-top: 30px;}
.page-box-2 .css-1lwbda4-MuiStack-root .MuiFormGroup-root.MuiFormGroup-row.css-qfz70r-MuiFormGroup-root {justify-content: center;}
.page-box-2 .css-1lwbda4-MuiStack-root .form-section p {font-size: 20px;}
.radio-button-line {display: flex;flex-wrap: wrap;align-items: center;justify-content: center;}
.radio-button-line p {margin: 0 !important;}
.page-box-2 .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root {margin-bottom: 12px;}
.page-box-2 .form-section .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root{width: 100%;}
.page-box-2 .css-1z0pfhy {width: auto;}
.page-box-2 .radio-tag {padding-left: 0;}
.page-box-2 small.text-danger {margin-right: 40px;margin-left: 40px;}

/* Account management page css end */


/* Privacy page css strat */

.radio-button {justify-content: center;margin: 10px 0;align-items: baseline;}
.page-textarea {width: 100%;padding: 25px;margin-top: 40px;border: 1px solid #31abf7;border-radius: 5px;}
.page-textarea textarea {overflow: overlay !important;width: 100% !important;height: 340px !important;padding-right: 25px;border: none;font-size: 13px;font-family: 'lora';line-height: 1.5;color: #606060;}
.MuiGrid-root.MuiGrid-container {display: block;}
.page-box .title-content span{font-size: 22px;color: #606060;}
.page-textarea textarea::-webkit-scrollbar {width: 10px;}
.page-textarea textarea::-webkit-scrollbar {width: 10px;}
.page-textarea textarea::-webkit-scrollbar-thumb {background: #0fa0fa;border-radius: 10px;}
.page-textarea textarea::-webkit-scrollbar-track {background: #e8f5fd;border-radius: 10px;}
/* .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {display: block;} */
.form-section .privacy-button{border: 1px solid #b1d836;box-shadow: none;background-color: #b1d836;margin: 10px auto;padding: 20px 50px;color: #081c23;border-radius: 1000px;font-family: lato;font-size: 18px;}
.form-section .privacy-button:hover {background-color: #31abf7;border: 1px solid #31abf7;}
.form-section .privacy-button-disabled{border: 1px solid #b1d836;box-shadow: none;opacity: 0.6; background-color: #b1d836;margin: 10px auto;padding: 20px 50px;color: #081c23;border-radius: 1000px;font-family: lato;font-size: 18px;}
.form-section label {line-height: 3.4375em;left: 15px;}
.form-section input {height: 3.4375em;}
.form-section label[data-shrink="true"] {
  left: 0;
  top: -10px;
}
/* Privacy page css end */

/* Patient Dashboard css strat */

.patient-detail {text-align: center;background-color: #e8f5fd;border: 1px solid #31abf7;box-shadow: none;margin: 50px auto; width: 100%;}
.row-title-video {font-size: 30px;color: #081c23;padding: 30px 0;border-bottom: none !important;font-weight: 700;}
.row-title-date {color: #606060;font-size: 18px;padding: 30px 0;text-align: right;border-bottom: none !important;}
.row-title-action {color: #606060;font-size: 18px;padding: 30px 0;text-align: right;border-bottom: none !important;}
.patient-table {min-width: auto;}
.patient-td {padding: 20px 0 !important;font-family: 'lato';}
.patient-row {border-top: 1.4px solid #0fa0fa;}
.patient-row svg {fill: #31abf7;cursor: pointer;}
.patient-iocn-vname a {color: #31abf7;text-decoration: none;font-size: 22px;padding-left: 25px;}
.patient-iocn-vname{display: flex;align-items: center;}

.video-name-date {display: flex;flex-wrap: wrap;justify-content: space-between;margin: 20px 0px;align-items: center;}
.video-name {font-size: 35px;font-family: lato bold;font-weight: 600;}
.video-date {color: #606060;font-size: 18px;font-family: 'Lato';}
.video-description {margin: 20px 0;color: #606060;font-family: 'Lato';padding: 0 15px;padding-bottom: 40px;font-size: 18px;}
.video-cls,.video-description {padding: 0 60px;}
.patient_video_top {padding-top: 60px;}
.patient_video_top {padding-top: 80px;padding-bottom: 80px;}

/* Patient Dashboard css end */

/* doctor Dashboard css start */

.table-bg {background: transparent;box-shadow: none;overflow: inherit;}
.css-jjtu05{background: #e8f5fd;border: 1px solid #31abf7;border-radius: 5px;}
.table-search{text-align: center;display: block;margin-top: 30px;}
.table-search-box{border: 1px solid #31abf7;}
tbody tr:nth-child(odd){background: #fff;}
.table-body tr td a {color: #31abf7;font-size: 16px;font-family: 'Lato-bold';}
.table-bg table th:not(:last-child), .table-bg table td:not(:last-child){
  border-right: 1px solid #31abf7;
}
.table-bg table tr th,.table-bg table tr:not(:last-child) td{
  border-bottom: 1px solid #31abf7;
}
.table-bg table tr th{
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  font-weight: 800;
}
.table-bg table tr td{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
 
}

.css-hz1bth-MuiDialog-container {
  background: #081c23d9;
}

.MuiBox-root.css-8atqhb {
  margin-bottom: 30px;
  margin-top: 10px;
}

.MuiBox-root .css-1vfgoos-MuiLinearProgress-root {
  height: 10px;
  border-radius: 30px;
  background-color: #e5f6fe;
  border: 1px solid #01aefd;
}

.css-8n2xj1-MuiLinearProgress-bar1{background-color: #01aefd;}

.VideoInput {
  text-align: center;
}

h2#customized-dialog-title svg.MuiSvgIcon-root {fill: #31abf7;}
.VideoInput .choose-file .MuiOutlinedInput-root input {border: 1px solid #31abf7;border-radius: 8px;}
p.patient_name {font-size: 25px;}
.upload_line_remove {padding: 30px 50px !important;border: none;padding-bottom: 10px !important;}
.video-upload-text + small.text-danger + textarea.video-upload-textarea + textarea.video-upload-textarea + .MuiBox-root.css-8atqhb {
  display: none;
}


/* doctor Dashboard css end */

.video-upload-textarea {width: 100% !important;margin: 20px 0;border: 1px solid #00cefb;padding: 10px;border-radius: 5px;font-size: 15px;font-family: 'Lato';}
.video-upload-text {
  width: 100%;
  margin-top: 20px;
}

.video-uplode-button {font-size: 16px;border: 1px solid #b1d836;box-shadow: none;background-color: #b1d836;margin: 10px auto;padding: 20px 35px;color: #081c23;border-radius: 1000px;font-family: lato;}
.video-uplode-button:hover {background-color: #31abf7;border: 1px solid #31abf7;}
.video-upload-text fieldset {border: 1px solid #61dafb !important;}
.patient-video-upload {text-align: center;}
textarea.video-upload-textarea:focus {outline: none !important;}


button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.css-hrm44d-MuiButtonBase-root-MuiIconButton-root {padding: 0;background: #003947;color: #fff;margin-left: 16px;}
.forgot-page-box{margin-top: -80px;background-color: #fff;border-radius: 5px;border: 1px solid #31abf7;}
label.error {color: red;}
.skelton-card {text-align: center;margin: 50px auto;width: 100%;}
.no-video {text-align: center;padding: 180px 0;}
body {padding-right: 0 !important;overflow: auto !important;	}
.footer-section {
  background-color: #0e3848;
  min-height: 150px;
  margin-top: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.button-back-regi {
  display: flex;
  justify-content: space-between;
}

body {padding-bottom: 190px;
  position: relative; min-height: 100%; height: auto !important;}

  .choose-file {
    width: 100%;
}
.video_Skeleton {
  padding: 0 60px;
  margin: 0 auto;
} 
.patient-tb-skeleton {
  margin-top: 50px;
}

/* back-button css */

.patient-back-button{font-size: 16px;border: 1px solid #b1d836;box-shadow: none;background-color: #b1d836;margin: 0 20px;padding: 20px 35px;color: #081c23;border-radius: 1000px;font-family: lato;}
.patient-back-button:hover{background-color: #31abf7;border: 1px solid #31abf7;}
.patient-back {text-align: center;}

/* Privacy Conetent css start */

.page-textarea .privacy_content {
    
  color: #606060;
  font-family: lora;
  
  height: 340px!important;
  line-height: 1.5;
  overflow: overlay;
  padding-right: 25px;
  width: 100%;
outline: none;
  border: none;
  overflow-x: hidden;
}

.title-content  .page-textarea .privacy_content a {
  color: #00a2f6 !important;
}

.title-content .page-textarea .privacy_content h1, .title-content .page-textarea .privacy_content h2, .title-content .page-textarea .privacy_content p, .title-content .page-textarea .privacy_content a, .title-content .page-textarea .privacy_content li {
  text-align: left !important;
font-family: 'lora';
color:#606060;
font-size: 15px;

}
.title-content .page-textarea .privacy_content li{list-style: inside;}


.title-content .page-textarea .privacy_content h2{font-size:20px;}
.title-content .page-textarea .privacy_content p{margin-top:10px;}

.page-textarea .privacy_content::-webkit-scrollbar {width: 10px;}
.page-textarea .privacy_content::-webkit-scrollbar {width: 10px;}
.page-textarea .privacy_content::-webkit-scrollbar-thumb {background: #0fa0fa;border-radius: 10px;}
.page-textarea .privacy_content::-webkit-scrollbar-track {background: #e8f5fd;border-radius: 10px;}

/* Privacy Conetent css end */


/*tricore 23 css end*/

@media (max-width:1100px){

  .container .mt-3.row .col-md-4.offset-md-4 {
    width: 50%;}
}

@media (max-width:524px) {

  .row-title-video {width: 50%;}

}

@media (max-width:435px) {

  .row-title-video {font-size: 24px;width: 50%;}
  .row-title-date{font-size: 16px;}
  .row-title-action{font-size: 16px;}

}


@media (max-width:394px) {

  .form-section .page-button {font-size: 18px; width: 45%;padding: 30px 30px;}
  
  
  }

@media (max-width:382px) {

  .row-title-video {font-size: 20px;}
  .row-title-date{font-size: 14px;}
  .row-title-action{font-size: 14px;}

}

@media (max-width:320px) {

  .row-title-video {    width: 40%;}
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-danger{
  color: red;
}
